import { IonAlert, IonButton, IonContent, IonModal, IonText } from "@ionic/react";
import { FriendLinkType } from "@src/app/store/workerFriends/model";
import { logEvent } from "@src/appV2/lib/analytics";
import { USER_EVENTS } from "@src/constants";
import { FC, useEffect } from "react";

import { FriendLinkAlertProps } from "./interface";
import { WorkerFriendProfilePicture } from "./WorkerFriendProfilePicture";
import "./style.scss";

export const FriendLinkAlert: FC<FriendLinkAlertProps> = ({
  isOpen,
  closeModal,
  handleOnContinueClick,
  workerId,
  header,
  hasProfilePic,
  type,
  name,
  qualification,
  imageUrl,
  context,
  ...props
}) => {
  let { message } = props;
  if (type === FriendLinkType.NEW_FRIEND || type === FriendLinkType.FRIEND_ALREADY_EXIST) {
    message = message.replace("{{name}}", `<strong>${name}`);
    message = message.replace("{{qualification}}", `(${qualification})</strong>`);
  }

  useEffect(() => {
    if (type === FriendLinkType.NEW_FRIEND && isOpen) {
      logEvent(USER_EVENTS.VIEW_ADD_FRIEND_MODAL, {
        requesteeId: workerId,
        context,
      });
    }
  }, [workerId, type, isOpen]);

  return type === FriendLinkType.NEW_FRIEND ? (
    <IonModal
      isOpen={isOpen}
      onWillDismiss={closeModal}
      cssClass="profile-picture-modal friend-link-alert-modal"
      data-testid="friend-link-modal"
    >
      <IonContent className="profile-picture-modal-container">
        <div className="profile-picture-modal-container-content">
          <IonText className="modal-title">
            <h2>{header}</h2>
          </IonText>
          <WorkerFriendProfilePicture
            name={name ?? ""}
            hasProfilePic={hasProfilePic || false}
            workerId={workerId ?? ""}
            imageUrl={imageUrl}
          />
          <IonText className="modal-subtitle">
            <p dangerouslySetInnerHTML={{ __html: message }} />
          </IonText>
        </div>
        <div className="profile-picture-modal-container-action">
          <IonButton
            expand="full"
            data-testid="add-friend-modal-button"
            shape="round"
            fill="solid"
            color="primary"
            onClick={handleOnContinueClick}
          >
            Add Friend
          </IonButton>
          <IonButton
            expand="full"
            shape="round"
            className="profile-picture-modal-cancel-action"
            fill="outline"
            onClick={closeModal}
          >
            Cancel
          </IonButton>
        </div>
      </IonContent>
    </IonModal>
  ) : (
    <IonAlert
      data-testId="friend-link-alert"
      cssClass="ion-friend-link-alert-popup"
      message={message}
      header={header}
      isOpen={isOpen}
      buttons={[
        {
          text: "OK",
          handler: closeModal,
        },
      ]}
    />
  );
};
