import { useLoadScript } from "@react-google-maps/api";
import { Libraries } from "@react-google-maps/api/dist/utils/make-load-script-url";
import { environmentConfig } from "@src/appV2/environment";
import constate from "constate";
import { useState } from "react";

const libraries: Libraries = ["places"];

function useGoogleMap() {
  const { isLoaded: isMapLoaded, loadError: mapLoadError } = useLoadScript({
    id: "google-map-script",
    googleMapsApiKey: environmentConfig.REACT_APP_GOOGLE_MAP_API,
    preventGoogleFontsLoading: false,
    language: "en",
    region: "US",
    version: "3.48",
    libraries,
  });
  const [googleMapCenter, setGoogleMapCenter] = useState<google.maps.LatLngLiteral>();
  const [googleMapZoomLevel, setGoogleMapZoomLevel] = useState(10);

  return {
    isMapLoaded,
    mapLoadError,
    googleMapCenter,
    setGoogleMapCenter,
    googleMapZoomLevel,
    setGoogleMapZoomLevel,
  };
}

export const [GoogleMapProvider, useGoogleMapContext] = constate(useGoogleMap);
